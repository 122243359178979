* {
  outline: none !important;
}

.hover-row:hover {
  background-color: rgba(35, 35, 35, 0.08);
}

.sp-search-header {
  text-align: center;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 99px */
  letter-spacing: -1.35px;
  text-transform: uppercase;
}

.sp-search-content {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}

.sp-search-box-container {
  display: flex;
  margin: auto;
  width: 100%;
  padding: 24px;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  margin-top: 66px;
  gap: 24px;
}

.sp-search-box-input {
  font-size: 32px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-family: TASA Orbiter Deck Medium;
  color: rgb(43, 22, 100);
  background: transparent;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
}

.sp-search-box-input.black {
  color: black;
}

.sp-search-box-input::placeholder {
  color: rgb(43, 22, 100);
  opacity: 0.6; /* Firefox */
}

.sp-search-box-input.black::placeholder {
  color: black;
}

.sp-search-box-input:focus {
  outline: none;
}

.sp-search-box-dropdown-container {
  width: 100%;
}

.sp-search-dropdown-header {
  font-family: TASA Orbiter Display Black;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.sp-search-dropdown-label {
  font-family: TASA Orbiter Deck Semibold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.sp-search-dropdown-item {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.sp-artist-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 55.556% */
}

.sp-artist-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.sp-artist-result-column-container {
  height: 60px;
  padding: 6px 6px 16px 6px;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(35, 35, 35, 0.05);
}

.sp-confirmed-offer-result-column-container {
  padding: 6px 0;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: white;
  border: 1px solid var(--stroke-soft-200, #f3f3f3);
  cursor: pointer;
}

.sp-confirmed-offer-result-column-container.selected {
  background: rgba(35, 35, 35, 0.05);
}

.signup-modal-label {
  color: #25320e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signup-modal-container {
  display: flex;
  width: 700px;
  padding: 64px;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.sing-up-modal-select {
  font-family: TASA Orbiter Display Medium;
  height: 27px;
  font-size: 20px;
}

.sing-up-modal-select:focus {
  border-bottom: 1px solid rgba(16, 0, 36, 0.3);
}

.signup-modal-input-container {
  outline: none;
  border-bottom: 1px solid rgba(16, 0, 36, 0.2);
  font-family: TASA Orbiter Deck Medium;
  background: none;
  width: 100%;
  font-size: 20px;
}

.signup-modal-input-container:focus {
  border-bottom: 1px solid rgba(16, 0, 36, 0.3);
}

.signup-modal-input-container::placeholder {
  color: rgba(16, 0, 36, 0.5);
}

.signup-modal-terms {
  color: rgb(43, 22, 100);
  padding-left: 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  font-family: TASA Orbiter Display Medium;
  line-height: normal;
}

.signup-modal-checkbox {
  background-color: #d9d9d9 !important;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.animated-value span {
  font-size: 24px;
  transition: all 0.2s ease-in-out;
}

.purple-background {
  background-color: #7371fc;
}

.flow-header-span {
  color: rgb(43, 22, 100);
  font-family: "TASA Orbiter Display Black";
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 111.111% */
  text-transform: capitalize;
}

.flow-header-span.black {
  color: #000000;
}

.flow-content-span {
  color: rgba(43, 22, 100, 0.6);
  font-feature-settings: "cv11" on, "cv10" on, "cv06" on, "cv04" on, "cv02" on, "ss01" on, "cv03" on, "cv05" on,
    "ss03" on, "salt" on;
  font-family: TASA Orbiter Deck Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */
}

.flow-content-span.black {
  color: rgba(0, 0, 0, 0.6);
}

.flow-content-header {
  color: #a7b1d5;
  font-feature-settings: "cv11" on, "cv10" on, "cv06" on, "cv04" on, "cv02" on, "ss01" on, "cv03" on, "cv05" on,
    "ss03" on, "salt" on;
  font-family: TASA Orbiter Deck Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 169.231% */
}

.spotify-hits-title {
  color: #000000;

  text-align: left;
  font-feature-settings: "cv11" on, "cv10" on, "cv06" on, "cv04" on, "cv02" on, "ss01" on, "cv03" on, "cv05" on,
    "ss03" on, "salt" on;
  font-family: TASA Orbiter Deck Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
}

.flow-pill-div {
  cursor: pointer;
  display: flex;
  padding: 10px 21px 10px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: #fff;
  opacity: 0.8;
  box-shadow: 2px 2px 2px 2px rgba(16, 0, 36, 0.08), 6px 6px 6px 6px rgba(16, 0, 36, 0.04);
}

.flow-pill-span {
  color: #000;
  font-feature-settings: "cv11" on, "cv10" on, "cv06" on, "cv04" on, "cv02" on, "ss01" on, "cv03" on, "cv05" on,
    "ss03" on, "salt" on;
  font-family: TASA Orbiter Display Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-menu-button {
  color: #fff;
  text-align: center;
  font-family: TASA Orbiter Display Medium;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 100% */
}

.button-continue {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 100px;
  background: rgb(245, 255, 102);
}

.button-continue:hover {
  box-shadow: 2px 2px 2px 2px rgba(245, 255, 102, 0.16), 6px 6px 6px 6px rgba(245, 255, 102, 0.08);
  background: rgb(245, 255, 102);
}

.button-continue.disabled {
  background: rgba(245, 255, 102, 0.2);
}

.button-continue-span {
  color: #000;
  font-feature-settings: "cv11" on, "cv10" on, "cv06" on, "cv04" on, "cv02" on, "ss01" on, "cv03" on, "cv05" on,
    "ss03" on, "salt" on;
  font-family: TASA Orbiter Display Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-continue-span.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.button-continue-span.white {
  color: #ffffff;
}

.button-next {
  display: flex;
  padding: 20px 36px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 100px;
  background: #7371fc;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.08);
}

.offers-menu-item {
  color: #000000;
  text-align: start;
  font-family: TASA Orbiter Deck Medium;
  font-size: 22px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 100% */
}

.offers-menu-item.selected {
  color: rgba(0, 0, 0, 0.6);
  cursor: auto;
}

.transparent-select:hover {
  border: none;
}

.custom-file-upload {
  display: block;
  background-color: transparent;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  overflow: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.custom-file-upload input {
  display: none;
}

.custom-file-upload.drag-over {
  background-color: rgba(100, 100, 100, 0.32); /* Highlight the drop area when files are dragged over */
}

.thanks-page-header {
  font-family: TASA Orbiter Display Black;
  font-size: 48px;
  font-weight: 900;
  line-height: 54px;
  letter-spacing: 0;
  text-align: left;
}

.thanks-page-content {
  font-family: TASA Orbiter Display Medium;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}

.rounded-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.rounded-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
}

.checkmark.selected {
  background-color: #7371fc;
  border: 1px solid #7371fc;
}

.rounded-checkbox input:checked + .checkmark:after {
  content: "\2713"; /* Unicode character for checkmark */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px; /* Adjust the font size as needed */
  color: #ffffff;
}

.public-estimate-box-container {
  box-shadow: 0 0 0 1px rgba(17, 17, 17, 0.04), 0 1px 1px 0px rgba(17, 17, 17, 0.04), 0 3px 3px 0 rgba(17, 17, 17, 0.03),
    0 6px 4px 0 rgba(17, 17, 17, 0.02), 0 11px 4px 0 rgba(17, 17, 17, 0.01), 0 32px 24px -12px rgba(17, 17, 17, 0.06);
  border-radius: 10px;
  align-items: center;
  height: 136px;
  display: grid;
  justify-content: center;
}
.other-artists-in-album-span:hover {
  background-color: #cccccc;
}

.scaled-logo {
  animation: scaleUp 0.8s forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

@keyframes loadingDots {
  0% {
    content: "";
  }

  33% {
    content: ".";
  }

  66% {
    content: "..";
  }

  100% {
    content: "...";
  }
}
.dots {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 20px;
}
.dots::after {
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: #666;
  margin-left: 2px;
  content: "";
  animation: loadingDots 0.9s infinite;
}
.song-table-song-components {
  border: 1px solid #f3f3f3;
  border-bottom: none;
}
.song-table-song-components:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.song-table-song-components:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #f3f3f3;
}
.song-table-song-components.selected {
  background: rgba(35, 35, 35, 0.12);
}
.song-table-song-components.disabled {
  background: rgba(35, 35, 35, 0.04);
  cursor: default;
}

.confirmed-offer-selection-div {
  cursor: pointer;
  display: grid;
  border-radius: 10px;
  background: #f7f7f7;
}

.confirmed-offer-selection-div.selected {
  background: #000000;
}

.confirmed-offer-selection-text {
  display: grid;
  gap: 8px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}

.confirmed-offer-selection-text.selected {
  background: #111111;
}

.table-row-hover {
  background: var(--bg-white-0, #fff);
}

.table-row-hover:hover {
  border-radius: 10px;
  border: 1px solid var(--stroke-soft-200, #e7e7e7);

  /* regular-shadow/small */
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}

.ellipses-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* or any specific width you want */
}

.visible-class {
  max-height: 500px;
  transition: max-height 1s ease-in-out;
}

.hidden-class {
  max-height: 0px;
  transition: max-height 1s ease-in-out;
}
