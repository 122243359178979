@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro";
  src: local("Aeonik Pro"), url("./assets/fonts/AeonikPro-Bold/AeonikPro-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro Bold";
  src: local("Aeonik Pro Bold"), url("./assets/fonts/AeonikPro-Bold/AeonikPro-Bold.otf") format("truetype");
}